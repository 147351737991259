$primary-foreground-color: #fff; // #fff default
$primary-background-color: #414042;  // #2B333F default
$center-big-play-button: false; // true default
$slider-bg-color: lighten($primary-background-color, 95%);

.video-js {
  font-size: 10px;
  color: $primary-foreground-color;

}

.video-js.vjs-fluid {
  padding-top: 500px;
  
  @media only screen and (max-width: 600px) {
    padding-top: 70%;
  }
}

.vjs-default-skin .vjs-big-play-button {
  font-size: 3em;
  $big-play-width: 3em; 
  $big-play-height: 1.5em;

  line-height: $big-play-height;
  height: $big-play-height;
  width: $big-play-width;

  border: 0.06666em solid $primary-foreground-color;
  border-radius: 0.3em;

  @if $center-big-play-button {
    /* Align center */
    left: 50%;
    top: 50%;
    margin-left: -($big-play-width / 2);
    margin-top: -($big-play-height / 2);   
  } @else {
    /* Align top left. 0.5em = 15px default */

  }
}

.video-js .vjs-big-play-button {
  background-color: $cc-blue;
  background-color: rgba($cc-blue, 0.95);
  border: none !important;
}

.video-js .vjs-control-bar,
.video-js .vjs-menu-button .vjs-menu-content {
  background-color: $primary-background-color;
  background-color: rgba($primary-background-color, 0.95);
  border: none !important;
}

.video-js .vjs-slider {
  background-color: $slider-bg-color;
  background-color: rgba($slider-bg-color, 0.5);
}

.video-js .vjs-volume-level,
.video-js .vjs-play-progress,
.video-js .vjs-slider-bar {
  background: $primary-foreground-color;
}

.video-js .vjs-load-progress {
  background: lighten($slider-bg-color, 25%);
  background: rgba($slider-bg-color, 0.5);
}

.video-js .vjs-load-progress div {
  background: lighten($slider-bg-color, 50%);
  background: rgba($slider-bg-color, 0.75);
}

.vjs-slider {
  background-color: $cc-black !important;
  
  * {
    background-color: $cc-black !important;
  }
}

.vjs-slider-bar {
  background-color: $cc-blue !important;
}

