form {
    .ff-form-success {
        color: #659e65;
    }
    
    .ff-form-errors {
        color: #ea6161;
    }

    .ff-errors {
        padding: 0;
        margin-top: 5px;
        margin-bottom: 10px;

        li {
            list-style: none;
            margin: 0;
            padding: 0;
            color: #ea6161;
        }
    }

    .hide {
        display: none;
    }

    .Form-item {
        &--action {
            display: flex;
            justify-content: flex-end;
        }
    }

    .Form-controls {
        &.textarea {
            height: 150px;
            overflow: hidden;
            border-radius: 30px;
        }
    }

    .input-fields {
        margin-top: 10px;        
        
        .file {
            input {
                opacity: 0;
                position: absolute;
                pointer-events: none;
                visibility: hidden;
            }
        }

        .file-container {
            display: flex;
            align-items: flex-start;

            .fake-file {
                margin-bottom: 0;
                border-radius: 100px;
            }

            .add-file-btn {
                background-color: $cc-lightGrey;
                color: $cc-black;
                font-weight: 500;
                border-color: $cc-lightGrey;
                transition: opacity .2s ease;
                margin-bottom: 0;
                margin-left: 10px;

                &:hover {
                    opacity: .7;
                }
            }

            @include mq($until: $viewport--sm) {
                flex-direction: column;

                .add-file-btn {
                    margin-left: 0;
                    margin-top: 10px;
                }
            }
        }
        
        label {
            display: none !important;            
        }
    
        textarea {
            height: 150px;
            border-radius: 30px;

            &::-webkit-scrollbar {
                width: 5px;
            }
            
            &::-webkit-scrollbar-track {
                background-color: $cc-lightGrey;
            }
            
            &::-webkit-scrollbar-thumb {
              background-color: $cc-blue;
              outline: 1px solid $cc-blue;
              cursor: pointer;
            }
        }
    
        input, textarea, .fake-file  {
            border: 1px solid $cc-lightGrey;
            background-color: white;
            font-size: 20px;
            font-family: $primaryFont;
            color: $cc-black !important;
            appearance: none;
            resize: none;
            padding: 15px 30px;
            width: 100%;
    
            &::placeholder {
                color: $cc-black;
            }

            @include mq($until: $viewport--sm) {
                font-size: 16px;
                line-height: 20px;
            }
        }
        
        button {
            font-family: $primaryFont !important;
            font-size: 20px;
            font-weight: 500;
            margin-top: 0;
            
            @include mq($until: $viewport--sm) {
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
}