.SpontaanSolliciterenPage {
    .apply {
        form {
            margin: 80px 0 140px;
            
            @include mq($until: $respNav) {
                margin: 30px 0 60px;
            }
            
            @include mq($until: $viewport--sm) {
                margin: 30px 0 40px;
            }
        }       
    }
}