.machine {
    &-overview {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 80px;
        margin-top: 120px;
        margin-bottom: 160px;

        &.FilterTiles {
            max-width: $container;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }

        @include mq($until: $viewport--sm) {
            margin-top: 40px;
            margin-bottom: 40px;

            &.FilterTiles {
                max-width: 320px;
                margin-left: auto;
                margin-right: auto;
                width: 100% !important;

                .FilterTile {
                    margin: 20px 0;
                }
            }
        }
    }

    &-tile {
        box-shadow: 0 3px 20px rgba(0, 0, 0, .16);
        border-radius: 12px;
        transition: border-color .2s ease, box-shadow .2s ease;
        border: 1px solid transparent;
        cursor: pointer;
        max-width: 340px;
        width: 100%;        
        height: 400px;
        
        &.FilterTile {
            margin: 30px;
        }

        > figure {
            height: 260px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            position: relative;

            img {
                object-fit: contain;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }

            picture {
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        > div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-top: 1px solid $cc-lightGrey;
            padding: 40px;
            transition: background-color .2s ease;  
            height: 140px;     
            text-align: center;     

            > aside {
                > p {
                    color: $cc-blue;
                }
            }

            > div {
                > p {
                    margin-bottom: 0;
                }
            }
        }

        &:hover {
            color: $cc-black !important;
            border-color: $cc-blue;
            box-shadow: 0 0px 20px rgba(0, 0, 0, .2);
            overflow: hidden;

            > div {
                background-color: $cc-lightGrey;
            }
        }
    }
}

.MachinesPage {
    .container {
        max-width: $container;
        margin-left: auto;
        margin-right: auto;
        padding: 0 20px;
    }

    .content-builder {
        padding-top: 0 !important;
    }

    .Breadcrumbs {
        margin-left: 0;
        padding-left: 0;

        @include mq($until: $viewport--sm) {
            flex-direction: column;

            > *:not(:first-child) {
                padding-left: 20px;
            }
        }
    }

    .Machines {
        &-detail {
            margin-top: 260px;

            &--intro, &--info {
                display: flex;
                margin-left: auto;
                margin-right: auto;
                max-width: $container;
                width: 100%;
                padding: 0 20px;

                > * {
                    &:first-child {
                        min-width: 960px;
                        max-width: 960px;
                        margin-right: 120px;
                    }
                }
            }

            .relevant-machines-overview {
                display: grid;
                grid-template-columns: repeat(4, 320px);
                grid-gap: 60px;
                width: max-content;                
                margin: 20px auto 0;
                padding: 0 20px;

                &-container {
                    margin: 20px 0 80px;

                    > h2 {
                        padding: 0 20px;
                        text-align: center;
                        margin-bottom: 50px;
                    }
                }

                .machine-tile {
                }

                @include mq($until: 1500px) {
                    grid-template-columns: repeat(3, 320px);
                }
                
                @include mq($until: 1150px) {
                    grid-template-columns: repeat(2, 320px);
                }
                
                @include mq($until: $viewport--md) {
                    grid-template-columns: 320px;
                }

                @include mq($until: $viewport--sm) {
                    grid-gap: 40px;
                }
            }

            &--intro {                
                margin-bottom: 50px;                                

                .info {                   
                    aside {
                        margin-bottom: 10px;

                        p {
                            color: $cc-grey;
                        }
                    }
                    
                    h2 {
                        margin-top: 20px;
                        font-size: 26px;
                        line-height: 32px;
                        font-weight: 400;
                    }

                    ul {
                        list-style: none;
                        padding-left: 0;
                        margin: 40px 0;
                        padding-top: 40px;
                        border-top: 1px solid $cc-grey;
                        
                        li {
                            display: flex;
                            margin-right: 10px;
                            font-size: 20px;
                            line-height: 26px;
                            max-width: 350px;

                            p {
                                margin-bottom: 0;
                            }
                        }

                        li + li {
                            margin-top: 20px;
                        }

                        li:before {
                            content: "+";
                            color: $cc-blue;
                            margin-right: 10px;
                        }
                    }

                    .ctas {
                        display: flex;

                        a {
                            margin-right: 20px;

                            &:first-of-type {
                                background-color: unset;
                                border-color: $cc-black;
                                color: $cc-black;
                                transition: opacity .2s ease;

                                &:hover {
                                    opacity: .7;
                                }
                            }
                        }
                    }                    
                }
            }

            &--info {
                padding-top: 120px;
                margin-bottom: 160px;

                article {
                    h2 {
                        font-size: 26px;
                        line-height: 32px;
                        font-weight: 500;
                        margin-bottom: 20px;
                    }

                    > .text {
                        max-width: 800px;

                        em {
                            font-weight: 400;
                            color: $cc-blue;
                            font-style: normal;
                        }
                    }

                    .image {
                        max-height: 500px;
                        width: 100%;
                        display: flex;
                        overflow: hidden;

                        img {
                            height: 100%;
                            object-fit: cover;
                            width: 100%;
                            display: flex;
                        }
                    }
                }

                .information {
                    > * {
                        margin-top: 80px;                        
                    }
                    
                    h2 {
                        margin-top: 0;
                        margin-bottom: -60px;
                    }

                    .video-js {                        
                        padding-top: 560px;
                    }
                }

                .technical-data {
                    width: 100%;

                    > article + article {
                        margin-top: 50px;
                    }

                    ul {
                        padding: 0;
                        margin: 0;

                        li {
                            list-style: none;                            
                            padding: 15px 0;
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                            border-bottom: 1px solid $cc-lightGrey;
                            
                            * {
                                font-size: 20px;
                                line-height: 26px;
                            }

                            &:first-of-type {
                                padding-top: 0;
                            }
                        }
                    }
                }
            }
            
            @include mq($until: 1500px) {
                &--intro, &--info { 
                    > * {
                        &:first-child {
                            min-width: 720px;
                            max-width: 720px;
                            margin-right: 70px;
                        }
                    }       
                    
                    #primary-slider {
                        .splide__slide {
                            height: 430px !important;
                        }
                    }
                    
                    #secondary-slider {
                        .splide__slide {
                            width: 190px !important;
                            height: 150px !important;
                        }
                    }
                }
            }

            @include mq($until: 1200px) {
                &--info {
                    .information {
                        > * {
                            margin-top: 40px;                        
                        }
                        
                        h2 {
                            margin-top: 0;
                            margin-bottom: -20px;
                        }
                    }
                }

                &--intro, &--info { 
                    > * {
                        &:first-child {
                            min-width: 600px;
                            max-width: 600px;
                            margin-right: 70px;
                        }
                    }       
                    
                    #primary-slider {
                        .splide__slide {
                            height: 380px !important;
                        }
                    }
                }
            }

            @include mq($until: 1100px) {
                &--intro { 
                    flex-direction: column;

                    .info {
                        margin-top: 50px;
                        width: fit-content;
                    }
                }

                &--info {
                    flex-direction: column-reverse;
                    padding-top: 30px;
                    margin-bottom: 80px;

                    .information {
                        .video-js {
                            margin-top: 30px;
                        }
                    }

                    .technical-data {
                        margin-bottom: 40px;
                    }
                }
            }

            @include mq($until: $viewport--md) {
                margin-top: 200px;

                &--intro {
                    .info {
                        h2 {
                            font-size: 22px;
                            line-height: 28px;
                        }
    
                        ul {
                            margin: 20px 0;
                            padding-top: 20px;
                        }
                    }
                }

                &--info {
                    .technical-data {
                        ul {
                            li {
                                padding: 10px 0;
                                
                                span {
                                    font-size: 19px;
                                    line-height: 25px;

                                    &:first-child {
                                        margin-right: 20px;
                                    }
                                }
                            }
                        }
                    }
                }


                &--intro, &--info { 
                    > * {
                        &:first-child {
                            min-width: unset;
                            max-width: unset;   
                            width: 100%;
                        }
                    }       
                }
            }

            @include mq($until: $viewport--sm) {
                margin-top: 140px;

                &--intro {
                    .info {
                        ul {
                            li {
                                font-size: 17px;
                                line-height: 23px;
                            }
    
                            li + li {
                                margin-top: 10px;
                            }
                        }
                    }
                }

                &--info {
                    .technical-data {
                        ul {
                            li {
                                padding: 5px 0;

                                span {
                                    font-size: 17px;
                                    line-height: 23px;
                                }
                            }
                        }
                    }

                    .information {
                        > * {
                            margin-top: 30px;                        
                        }

                        .video-js {
                            padding-top: 320px;
                        }
                    }
                }

                &--intro, &--info { 
                    #primary-slider {
                        .splide__slide {
                            height: 300px !important;
                        }
                    }

                    #secondary-slider {
                        display: none;
                    }
                }
            }

            @include mq($until: 400px) {
                &--info {
                    .technical-data {
                        ul {
                            li {
                                display: flex;
                                flex-direction: column;
                            }
                        }
                    }
                }
            }
        }        
    }
}