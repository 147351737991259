.cta-block {
    background-color: $cc-black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 80px 40px;

    .title, .text {
        color: $cc-white;
        text-align: center;
        max-width: 960px;
    }

    .text {
        margin-top: 30px;
    }

    .buttons {
        margin-top: 20px;
        display: flex;
        
        a {
            margin: 10px;
        }
    }

    form {
        padding-top: 30px;
        max-width: 960px;
        width: 100%;
    }

    @include mq($until: $viewport--sm) {
        padding: 60px 20px;
    }
}