.page-header {
    display: flex;
    align-items: center;
    position: relative;

    @include mq($from: 1600px) {
        width: 1700px !important;
    }

    .text {
        display: flex;
        align-items: center;
   
        h1 {
            margin-left: 330px;
            padding-top: 250px;

            &.Banner-title {
                overflow: hidden;

                p {
                    margin-bottom: 0;
                }

                &.show {
                    opacity: 1 !important;
                }
            }
        }

        > div {
            margin-top: 20px;
        }
    }

    &-container {
        background-color: $cc-black;
        padding-bottom: 200px;
        position: relative;

        &-dark {
            h1 {
                em {
                    color: $cc-white;
                }
            }
        }

        .special-scroll {
            position: absolute;
            bottom: 0;
        }
        
        @include mq($until: 1400px) {
            padding-bottom: 120px;

            .page-header {
                svg {
                    z-index: 0;
                }
            }
        }
       
        @include mq($until: 1000px) {
            .page-header {
                border-bottom: none;
            }
        }
        
        @include mq($until: 800px) {
            padding-bottom: 20px;
        }
    }

    .with-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 160px;

        h1 {
            margin-left: 0;
            max-width: 1020px;
        }

        > div {
            max-width: 640px;
        }

        .Filter {
            ul {
                padding: 0;
                margin: 0;
                display: flex;
                align-items: center;
                max-width: 960px;
                flex-wrap: wrap;

                li {
                    margin: 0;
                    list-style: none;
                    margin-right: 10px;
                    margin-bottom: 10px;

                    .cta {
                        word-break: keep-all;
                        
                        &:not(.active) {
                            color: $cc-black;
                            border-color: $cc-black;
                            background-color: unset;
                            transition: border-color .2s ease, color .2s ease;

                            &:hover {
                                border-color: $cc-blue;
                                color: $cc-blue;
                            }
                        }

                        &.active {
                            &:hover {
                                color: $cc-white;
                                border-color: $cc-blue;
                                background-color: $cc-blue;
                            }
                        }
                    }
                }
            }
        }
    }

    @include mq($from: 2200px) {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        transform: translateX(-220px);
    }

    @include mq($until: 1650px) {
        .text {
            h1 {
                margin-left: 100px;
                margin-right: 20px;
            }

            &.with-text {
                margin-left: 100px;

                h1 {
                    margin-left: 0;
                }
            }
        }

        .Filter {
            margin-right: 20px;
        }
    }

    @include mq($until: 1400px) {
        align-items: flex-start;
        min-height: 600px;

        svg {
            min-height: 600px;
            max-height: 600px;
            position: absolute;
            opacity: .1;
            z-index: -1;
            pointer-events: none;
        }

        .text {
            h1 {                
                margin-right: 100px;
            }
        }
    }

    @include mq($until: 1000px) {
        padding-bottom: 40px;

        .text {
            &:not(.with-text) {
                h1 {
                    padding-bottom: 50px;
                }
            }
        }
    }

    @include mq($from: $viewport--md, $until: 1400px) {
        min-height: unset;
    }

    @include mq($until: $viewport--md) {
        align-items: center;
        min-height: 450px;
        border-bottom: 1px solid #7AA7EB;

        svg {
            min-height: 450px;
            max-height: 450px;
            width: fit-content;
        }

        .text {
            padding-top: calc(#{$navHeight} + 60px);

            h1 {          
                padding-top: 0;           
                margin-left: 60px;
                margin-right: 60px;
            }

            &.with-text {
                margin-left: 40px;
                margin-right: 40px;

                h1 {
                    padding-top: 0;
                }
            }
        }
    }

    @include mq($until: $viewport--sm) {
        

        .text {
            h1 {                           
                margin-left: 20px;
                margin-right: 20px;
            }

            &.with-text {
                margin-left: 20px;
                margin-right: 20px;

                .Filter {
                    a {
                        &.cta {
                            padding: 10px 20px;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
} 