a {
    text-decoration: none;
    color: $cc-black;
    font-weight: 500;
    font-size: 20px; 
    transition: color .2s ease;

    &:hover {
        color: $cc-blue;

        p {
            color: $cc-black;
        }
    }
}

h1 {
    color: $cc-blue;
    font-size: 74px;
    line-height: 74px;
    font-weight: 500;

    em {
        font-style: normal;
        color: $cc-black;    
    }

    @include mq($until: $viewport--md) {
        font-size: 54px;
        line-height: 54px;
    }

    @include mq($until: $viewport--sm) {
        font-size: 46px;
        line-height: 48px;
    }
}

h2 {
    font-weight: 500;
    font-size: 40px;
    line-height: 40px;

    @include mq($until: $viewport--md) {
        font-size: 36px;
        line-height: 36px;
    }

    @include mq($until: $viewport--sm) {
        font-size: 28px;
        line-height: 28px;
    }
}

h3 {
    font-size: 26px;
    font-weight: bold;
    line-height: 30px;

    @include mq($until: $viewport--md) {
        font-size: 20px;
        line-height: 24px;
    }

    @include mq($until: $viewport--sm) {
        font-size: 18px;
        line-height: 22px;
    }
}

.split-child {
    overflow: hidden;
}

div:not(.split-child) {
    > p {
        font-size: 20px;
        line-height: 26px;
        
        & > * {
            font-size: 20px;
            line-height: 26px;        
        }

        @include mq($until: $viewport--md) {
            em {
                font-size: 18px;
                line-height: 22px;
            }

            font-size: 18px;
            line-height: 22px;
        }
    
        @include mq($until: $viewport--sm) {
            em {
                font-size: 16px;
                line-height: 20px;
                margin-bottom: 10px;
            }

            font-size: 16px;
            line-height: 20px;
            margin-bottom: 10px;
        }
    }
}

a {
    @include mq($until: $viewport--sm) {
        font-size: 16px;
        line-height: 20px;
    }
}

aside {
    > p {
        margin-bottom: 5px;
        font-weight: 500;
        font-size: 14px;
    }
}

a {
    &.active-page {
        color: $cc-blue;
    }
}

input {
    border-radius: 200px;
    background-color: $cc-white;
    font-size: 20px;
    font-family: $primaryFont;
    border: none;
    padding: 20px 30px;

    &::placeholder {
        color: $cc-black;
    }
}

.subscribe {    
    form {
        display: flex;
        
        button {
            width: 56px;
            height: 56px;
            background-color: $cc-blue;
            border-radius: 50%;
            padding: 0;
    
            &:before {
                content: '';
                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.166' height='12.918' viewBox='0 0 7.166 12.918'%3E%3Cpath id='Path_208' data-name='Path 208' d='M-10096.485,530.473l-6.105,6.106,6.105,6.106' transform='translate(-10096.132 543.038) rotate(180)' fill='none' stroke='%23E6E5E6' stroke-width='1'/%3E%3C/svg%3E");
                background-size: 100%;
                width: 7px;
                height: 13px;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
    }
}

.go-back-detail {    
    display: flex;
    align-items: center;
    max-width: $container;
    width: 100%;
    margin: 0 auto;
    padding: 20px;    

    svg {
        transform: rotate(180deg);
        margin-right: 10px;
        transition: transform .2s ease;
    }

    span {
        font-size: 14px;
        font-weight: 500;
        line-height: 26px;
        color: $cc-grey;
        transition: transform .2s ease;
    }

    &:hover {
        svg {
            transform: rotate(180deg) translateX(5px);
        }

        span {
            transform: translateX(-5px);
        }
    }
}

.socials {
    a {
        svg {
            path {
                transition: fill .2s ease;
            }
        }

        &:hover {
            svg {
                path {
                    fill: $cc-blue;
                }
            }
        }
    }
}

.id-location {
    padding-top: 150px;
    margin-top: -150px;
}

figure {
    picture {
        height: 100%;
        width: 100%;
    }
}