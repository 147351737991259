.Navigation {
    &--top {
        width: 100%;
        height: 40px;
        background-color: $cc-lightGrey;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        transition: opacity .2s ease;
        
        nav {
            margin: 40px auto;
            max-width: $container;
            padding: 0 20px; 
            width: 100%;
            display: flex;
            justify-content: flex-end;
            width: 100%;
            
            ul {
                margin-bottom: 0;  
                padding-left: 0;
                display: flex;                 
                padding-right: 25px;
    
                li {
                    list-style: none;
                    margin-bottom: 0;
                    display: flex;
                    align-items: center;
                    margin-left: 25px;
    
                    a {                    
                        font-size: 16px;                  
                    }    
                }
            }

            .socials {
                display: flex;
                align-items: center;                
                padding-left: 25px;
                border-left: 1px solid $cc-blue;
                height: 20px;
                
                a {
                    display: flex;
                    align-items: center;
                    height: 100%;

                    svg {
                        max-width: 23px;

                        path {
                            fill: $cc-black;

                        }
                    }
                    
                    &:hover {
                        svg {
                            path {
                                fill: $cc-blue;
                            }
                        }
                    }
                }

                a + a {
                    margin-left: 15px;
                }
            }
        }
    }

    &--main {
        display: flex;
        justify-content: space-between;
        margin: 40px auto;
        max-width: $container;
        padding: 0 20px;
        width: 100%;

        .hamburger {
            transition: opacity .2s ease, background-color .2s ease;

            span {
                display: block;
                position: relative;
                height: 2px;
                width: 30px;
                border-radius: 2px;
                opacity: 1;
                background-color: $cc-black;
    
                transform: rotate(0deg);
                transition: .3s ease-in-out;
    
                &:nth-child(1) {
                    top: 1px;
                }
    
                &:nth-child(2) {
                    top: 7px;
                }
    
                &:nth-child(3) {
                    top: 13px;
                }
            }
        }

        nav {
            transition: opacity .2s ease;

            ul {
                li {
                    list-style: none;

                    a {
                        &.active-page {
                            color: $cc-black;
                            border-bottom: 1px solid $cc-blue;
                        }
                    }
                }
            }

            > ul {
                display: flex;
                align-items: center;
                margin-bottom: 0;

                > li {
                    margin-bottom: 0;
                    display: flex;
                    justify-content: center;

                    &:not(:last-child) {
                        margin-right: 50px;
                    }

                    > a {
                        z-index: 1;
                    }

                    .sub-items {     
                        padding-top: 40px;
                        padding-left: 0;                                  
                        position: absolute;
                        pointer-events: none;                        
                        opacity: 0;
                        transform: translateY(-10px);
                        transition: opacity .2s ease, transform .2s ease;

                        div {
                            background-color: #f6f6f6;
                            padding: 20px 40px; 
                            display: flex;
                            flex-direction: column;
                            align-items: center;   
                            
                            li {
                                margin-bottom: 0;
                                padding-bottom: 5px;

                                a {
                                    transition: color .2s ease;

                                    &.active-page {
                                        color: $cc-blue;
                                        border-color: $cc-blue;
                                    }

                                    &:hover {
                                        color: $cc-blue;
                                    }
                                }
                            }
                        }
                    }

                    &:hover {
                        .sub-items {
                            opacity: 1;
                            transform: translateY(0);    
                            pointer-events: all;                        
                        }
                    }
                }
            }
        }
    }

    @include mq($until: 1200px) {
        &--main {
            .logo {
                margin-right: 20px;
                max-width: 200px;

                svg {
                    width: 100%;
                }
            }
        }
    }
    
    @include mq($until: 1100px) {
        &--main  {
            nav {
                > ul {
                    > li {
                        &:not(:last-child) {
                            margin-right: 30px;
                        }
                    }
                }
            }
        }
    }

    @include mq($from: $respNav) {
        &--main {
            .hamburger {
                opacity: 0;
                pointer-events: none;
                position: absolute;
            }
        }
    }


    @include mq($until: $respNav) {
        &--main {
            margin: 20px 0;

            .hamburger {                
                position: relative;
            }

            nav {
                > ul {
                    > li {
                        display: flex;
                        flex-direction: column;

                        a {
                            &.active-page {
                                width: max-content;
                            }
                        }

                        .sub-items {     
                            position: relative;
                            opacity: 1;
                            transform: translateY(0px);
                            padding-top: 0;                            
            
                            div {
                                align-items: flex-start;
                                padding: 5px 10px;
                                background-color: unset;

                                li + li {
                                    margin-top: 0 !important;
                                }
                                
                                li {                                            
                                    a {
                                        width: max-content;            
                                    }
                                }
                            }
                        }            
                    }
                }
            }
        }
    }

    @include mq($until: $viewport--sm) {
        &--top {
            justify-content: flex-start;
        }

        &--main {
            > nav {
                padding-top: 100px !important;
            }
        }
    }

    @include mq($until: 400px) {
        &--main {
            .logo {
                max-width: 170px;                
            }
        }
    }
}

@include mq($until: $respNav) {
    html, body {
        &.navigation-open {
            overflow: hidden;
        }
    }

    .Navigation {
        &--main {
            position: relative;
            align-items: center;
            z-index: 10;
    
            nav {
                opacity: 0;
                pointer-events: none;                                   
                position: fixed;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-color: $cc-lightGrey;
            }
    
            .hamburger {
                opacity: 1;
                pointer-events: all;
                cursor: pointer;
                background-color: $cc-white;
                height: 20px;
            }
        }
    
        &--top {
            z-index: 11;
            position: absolute;
            opacity: 0;
            pointer-events: none;
            background-color: $cc-lightestGrey;
            bottom: 0;
        }
    }

    .Header {        
        position: fixed;

        &.open {
            width: 100vw;
            height: 100%;
            overflow-y: scroll;           
        }
    }
    
    .Nav {
        &.Navigation {
            &--main {
                transition: background-color .2s ease;
            }
        }

        &:not(.open) {
            &.Navigation {
                &--main {
                    padding: 20px;
                    margin: 0;
                    background-color: $cc-white;
                    border-bottom: 1px solid $cc-blue;
                }
            }
        }

        &.open {
            &.Navigation {
                &--top {                
                    opacity: 1;
                    pointer-events: all;
                }
    
                &--main {
                    .logo {
                        z-index: 1;
                    }
                    
                    nav {
                        opacity: 1;
                        pointer-events: all; 
                        padding-top: 140px;
                        
                        ul {
                            flex-direction: column;
                            align-items: flex-start;  
                            
                            li + li {
                                margin-top: 20px;
                            }

                            li {
                                a {
                                    &.cta {
                                        width: fit-content;
                                    }
                                }
                            }
                        }
                    }
    
                    .hamburger {
                        background-color: $cc-lightGrey;

                        span {                       
                            &:nth-child(1) {
                                top: 9px;
                                -webkit-transform: rotate(135deg);
                                -moz-transform: rotate(135deg);
                                -o-transform: rotate(135deg);
                                transform: rotate(135deg);
                            }
                
                            &:nth-child(2) {
                                opacity: 0;
                            }
                
                            &:nth-child(3) {
                                top: 6px;
                                -webkit-transform: rotate(-135deg);
                                -moz-transform: rotate(-135deg);
                                -o-transform: rotate(-135deg);
                                transform: rotate(-135deg);
                            }
                        }
                    }
                }
            }
        }
    }
}

