.Footer {
    max-width: $container;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 80px 20px 0;

    &-top {
        display: grid;
        grid-template-columns: auto 80px auto 40px auto 80px 500px 60px 100px;
        grid-template-areas: 'logo . primary . secondary . subscribe . socials';

        .logo {
            grid-area: logo;

            svg {
                max-width: 160px;
            }
        }

        .primary {
            grid-area: primary;
        }

        .secondary {
            grid-area: secondary;
        }

        .primary, .secondary {
            ul {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding-left: 0;
                margin-bottom: 0;
            }
        }

        .subscribe {
            grid-area: subscribe;
            
            label {
                display: none;
            }

            input {
                border: 1px solid $cc-lightGrey;
            }

            h4 {
                font-size: 20px;
                line-height: 20px;
                font-weight: 500;
                margin-bottom: 5px;    
            }

            form {
                flex-direction: column;
                
                .ff-form-success, .ff-form-errors{
                    p {
                        margin-bottom: 0;
                    }
                }

                .input-fields {
                    display: flex;
                    width: 100%;

                    .Form-controls {
                        &:not(.Form-item--action) {
                            width: 100%;
                        }
                    }

                    .Form-item--action {
                        margin-left: 10px;
                    }
                }
            }
        }

        .socials {
            grid-area: socials;
            display: flex;
            justify-content: flex-end;

            a {
                margin-left: 15px;

                &:hover {
                    svg {
                        path {
                            fill: $cc-blue;
                        }
                    }
                }
            }

            svg {
                height: 20px;
                max-width: 20px;
                width: 100%;

                path {
                    fill: $cc-black;

                }
            }  
        }
    }

    &-bottom {
        border: none;
        display: flex;
        justify-content: space-between;
        padding: 40px 0;

        * {
            color: $cc-grey !important;
            font-size: 14px;
            line-height: 26px;
            font-weight: 500;
        }

        .privacy-pages {
            display: flex;

            > a {
                margin-right: 20px;
            }            
        }

        .site-by {
            span {
                transition: color .2s ease;
            }

            &:hover {
                color: $cc-grey !important;

                span {
                    color: $cc-blue !important;
                }
            }
        }
    }

    @include mq($until: 1500px) {
        .socials {
            align-items: center;
        }

        &-top {
            grid-template-columns: auto 80px 250px 40px 200px 100px 100px;
            grid-template-areas:
                'logo . primary . secondary . socials'
                'subscribe subscribe subscribe . . . .';
            grid-row-gap: 50px;
        }
    }

    @include mq($until: 1000px) {
        padding: 20px 20px 0;

        &-top {
            grid-template-columns: 250px 200px 100px;
            grid-template-areas:
                'logo . socials'
                'primary secondary .'
                'subscribe subscribe subscribe';
        }
    }

    @include mq($until: $viewport--sm) {
        &-top {
            grid-template-columns: repeat(2, auto);
            grid-template-areas:
                'logo socials'
                'primary .'
                'secondary .'
                'subscribe subscribe';
            grid-row-gap: 20px;
        }
    }
}