/* Settings: Base */

// Namespace
$namespace:                            null !default;

// Debugging
$debug:                                false !default;

// Paths
$path:                                 '../' !default;
$path-css:                             '#{$path}css/' !default;
$path-fonts:                           '#{$path}fonts/' !default;
$path-img:                             '#{$path}img/' !default;
$path-js:                              '#{$path}js/' !default;
$path-sass:                            '#{$path}sass/' !default;

// Units
$unit:                                 px !default;

// Typography
$font-size:                            16 !default;
$line-height:                          24 !default;

// Font-smoothing
$font-smoothing:                       true !default;

// Spacing
$spacing-unit:                         $line-height !default;
$spacing-unit--xs:                     $spacing-unit / 4;
$spacing-unit--sm:                     $spacing-unit / 2;
$spacing-unit--md:                     $spacing-unit;
$spacing-unit--lg:                     $spacing-unit * 2;
$spacing-unit--xl:                     $spacing-unit * 4;
$spacing-unit--xxl:                    $spacing-unit * 4;

// Breakpoints
$viewport--xs:                         320 !default;
$viewport--sm:                         600 !default;
$viewport--md:                         768 !default;
$viewport--ml:                         960 !default;
$viewport--lg:                         1024 !default;
$viewport--xl:                         1280 !default;
$viewport--xxl:                        1920 !default;

$resp-nav:                             1210 !default;
$alignment-large:                      160px !default;
$alignment-medium:                     320px !default;

$spacing--xxl:                         320px !default;
$spacing--lg:                          250px !default;
$spacing--md:                          200px !default;
$spacing--sm:                          150px !default;
$spacing--xs:                          100px !default;
$spacing--base-tablet:                 60px !default;
$spacing--base-mobile:                 20px !default;

$spacing--xxl-r-lg:                    200px !default;
$spacing--sm-r-lg:                     80px !default;

$container:                            1640px !default;
$navHeight:                            130px !default;
$respNav:                              960px !default;

@media only screen and(max-width: $respNav) {
    $navHeight:                        60px !global;
}

// Media types
$mq-type:                              'screen' !default;

// Constants
$SIZES: (
    ExtraSmall: 'xs',
    Small: 'sm',
    Medium: 'md',
    Large: 'lg',
    ExtraLarge: 'xl',
    ExtraExtraLarge: 'xxl'
);

$UNITS: (
    'px', 'cm', 'mm', '%', 'ch', 'pica', 'in', 'em',
    'rem', 'pt', 'pc', 'ex', 'vw', 'vh', 'vmin', 'vmax'
);
