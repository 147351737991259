

.slideshow-container {
    max-width: 960px;

    &.videos {
        .splide {
            &__slide {
                height: auto !important;
            }
            
            .video-js {
                width: 480px;
            }
        }
    }

    .splide {
        &__arrow {
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $cc-white;
            border: 1px solid $cc-grey;
            transition: opacity .2s ease;

            &:disabled {
                opacity: 0;
                pointer-events: none;
            }
        }

        &#primary-slider {            
            .splide {
                &__slide {
                    height: 640px;
                }
            }
        }

        &__slide {
            border-radius: 12px;
            overflow: hidden;
            border: 1px solid $cc-grey;
        }

        picture {            
            img {
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        img {
            width : 100%;
            height: 100%;
            object-fit: cover;
        }
    
        &#secondary-slider {
            margin-top: 10px;

            .splide {
                &__slide {
                    border-radius: 12px;
                    border-width: 1px;
                    cursor: pointer;
                    transition: border-color .2s ease, opacity .2s ease;
                    overflow: hidden;

                    &.is-active {
                        border-color: $cc-blue;
                    }

                    &:not(.is-active) {
                        opacity: .7;

                        &:hover {
                            opacity: 1;
                            border-color: $cc-blue;
                        }
                    }
                }                
            }
        }
    }
}