.content-builder {
    padding: 80px 0;    

    > article {
        display: flex;      
        align-items: center;   
        margin-left: auto;
        margin-right: auto; 

        img {
            height: 100%;
            object-fit: cover;
        }

        &.images-contain {
            min-height: unset;
            
            figure {
                height: auto;
                min-height: 350px;
                max-height: unset;
                
                img {
                    object-fit: contain !important;            
                }
            }
        }

        ul {
            li {
                list-style: disc;
            }
        }

        &.only-image {
            &:not(.fixed-height) {
                figure {
                    height: unset !important;
                    max-height: unset !important;
    
                    picture {
                        position: relative !important;
    
                        img {
                            position: relative !important;
                        }
                    }
                }
            }
        }

        .fancybox {
            height: 100%;

            picture {
                top: 0;
                left: 0;
            }
        }

        .video-js {
            &.vjs-fluid {
                max-width: 480px;
            }
        }

        figure {
            picture {   
                position: absolute;
                top: 0;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }
    
        > .text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;            
            width: 500px;

            > * {
                max-width: 500px;
            }

            > div {
                margin: 20px 0;
            }
        }
        
        > .image {
            position: relative;
            height: 560px;
            display: flex;
            align-items: center;

            figure {                
                min-width: 50vw;
                height: 560px;
                background-size: cover;
                background-position: left top;
                position: absolute;
                right: 0;
                border-radius: 10px;
                overflow: hidden;
    
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                picture {   
                    position: absolute;

                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }

                @include mq($from: 2800px) {
                    max-width: 640px;
                    min-width: 640px;
                }
            }
        }

        .buttons {
            display: flex;
            flex-wrap: wrap;
            margin: 0 !important;
        
            > a {
                margin-right: 10px;
            }
        }

        > * {
            &:nth-child(2) {
                margin-left: $alignment-medium;
            }
        }

        &.normal, &.bigger-image, &.two-images {
            .splide {
                &__arrow {
                    width: 40px;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $cc-white;
                    border: 1px solid $cc-grey;
                    transition: opacity .2s ease;
        
                    &:disabled {
                        opacity: 0;
                        pointer-events: none;
                    }
                }

                picture {
                    position: relative;

                    img {
                        position: relative !important;
                    }
                }
        
                &#primary-slider {            
                    .splide {
                        &__slide {
                            height: 640px;
                        }
                    }
                }

                &__list {
                    display: flex;
                    max-width: 480px;
                }
        
                &__slide {
                    border-radius: 0;
                    border: none;
                }
        
                picture {            
                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
        
                img {
                    width : 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        &.bigger-image, &.full-image {
            max-width: $container;
            padding: 80px 20px;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            justify-content: flex-end;

            > * {
                &:nth-child(2) {
                    max-width: 640px;
                    width: 100%;
                }
            }
        }

        &.reversed {
            @include mq($from: 900px) {
                flex-direction: row-reverse;
            
                > * {
                    &:first-child {
                        margin-left: $alignment-medium;
                    }

                    &:nth-child(2) {
                        margin-left: 0;
                    }
                }
            }
        }

        &.two-images {
            .images {                
                width: 100%;
                display: flex;
                justify-content: space-between;

                > figure {
                    height: 400px;
                }
            }

            @include mq($until: 1150px) {
                .images {
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                }
            }
            
            @include mq($until: $viewport--sm) {
                .images {
                    > figure {
                        height: 250px;
                        min-height: unset;
                    }
                }
            }
        }

        &.normal, &.two-images {
            max-width: 1320px;
            padding: 80px 20px;
            width: 100%;

            .image {
                max-width: 480px;
                width: 100%;
            }

            &.reverse {

            }
        }
        
        &.bigger-image {   
            min-height: 760px;

            > figure {                
                min-height: 760px;
                max-height: 760px;
            }
        }
        
        &.full-image {           
            .text {
                padding-left: 0px;
                margin-left: 320px;
            }
        }
        
        &.only-image {
            margin-left: 0;
            margin-right: 0;
            padding: 80px 0;

            figure {
                width: 100%;
                max-width: unset;   
                max-height: unset;            
                height: 36vw;
                max-height: 1100px;
                
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    @include mq($from: 3000px) {
        > article {
            &.only-image {
                figure {
                    max-height: unset;
                }
            }
        }
    }

    @include mq($until: 1800px) {
        > article {
            &.full-image {
                .text {
                    margin-left: 200px;
                }
            }
        }
    }


    @include mq($from: 900px, $until: 1500px) {
        > article {
            &.reversed {
                > * {
                    &:first-child {
                        margin-left: 110px !important;
                    }
                }
            }
        }
    }

    @include mq($until: 1500px) {
        > article {
            justify-content: center !important;

            // &:not(.reversed) {
            //     > * {
            //         &:nth-child(2) {
            //             margin-left: 110px !important;
            //         }
            //     }
            // }            

            &.full-image {
                .image {
                    max-width: 500px;
                    width: 100%;
                }
            }
        }
    }

    @include mq ($from: 900px, $until: 1500px) {
        > article {
            &:not(.reversed) {
                &.normal, &.bigger-image {
                    > * {
                        &:nth-child(2) {
                            margin-left: 120px;
                        }
                    }

                    figure {
                        width: 400px !important;
                        height: 480px;
                    }
                }
            }

            &.full-image {
                .text {
                    margin-left: 100px;
                }
            }
        }
    }

    @include mq($until: 1250px) {
        > article {
            padding: 20px;

            > * {
                &:nth-child(2) {
                    max-width: 500px !important;
                    width: 100% !important;

                    &.text {
                        padding-right: 80px;
                    }
                }
            }
        }
    }

    @include mq($until: 900px) {
        > article {
            flex-direction: column;
            align-items: flex-start;

            &:not(.only-image) {
                padding: 20px !important;
            }

            &.only-image {
                padding: 20px 0 !important;
            }

            .text {
                max-width: 500px;
                width: 100%;

                h3 {
                    margin-bottom: 0;
                }
            }

            > * {
                &:nth-child(2) {
                    margin-left: 0 !important;
                }
            }
            
            &.bigger-image {
                .image {
                    border-radius: 10px;
                    min-height: unset;
                    margin: 50px 0;
                    width: 100% !important;
                    max-width: unset !important;
                    max-height: 500px;
                    overflow: hidden;
                    height: 340px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            &.full-image {
                .image {
                    max-width: unset;
                    margin-bottom: 40px;

                    figure {
                        min-width: unset;
                        width: 100%;
                    }
                }
            }

            &.normal {
                .image {
                    margin-top: 40px;
                    border-radius: 10px;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }

    @include mq($until: $viewport--md) {
        > article {
            &.only-image {
                .image {
                    height: 500px;
                }
            }
        }
    }

    @include mq($until: $viewport--sm) {
        padding: 40px 0;

        > article {
            &:not(.only-image) {
                .image {
                    border-radius: 10px;
                    overflow: hidden;
                    height: 340px;
    
                    .slideshow-container, .splide, .splide__track, .splide__list, .splide__slide, .fancybox, picture, img {                        
                        width: 100% !important;
                        height: 100% !important;
                        background-size: cover;
                        object-fit: cover;                        
                    }

                    img {
                        border-radius: 10px;
                    }
                }
            }

            &.only-image {
                .image {
                    height: 350px;
                }
            }
        }
    }
}

.Contentbuilder {
    &-detail {
        max-width: 980px;
        margin: 80px auto 100px;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
    
        > * {
            &:nth-child(n+1) {
                margin-top: 40px;
            }
        }

        &--topTitle {
            max-width: 700px;
            margin-bottom: 40px;

            aside {
                p {
                    text-transform: uppercase;
                    color: $cc-blue;
                }
            }
    
            h2 {
                font-size: 54px;
                line-height: 64px;
                font-weight: 500;
            }
        }

        &--titleText {
            display: flex;
            flex-direction: column;

            h2 {
                font-size: 26px;
                font-weight: bold;
                line-height: 30px;
                margin-bottom: 10px;
            }
        }

        &--titleBig {
            font-size: 54px;
            line-height: 64px;
            font-weight: 500;
        }

        &--image {    
            max-width: 640px;
            max-height: 440px;

            img {
                max-width: 640px;
                max-height: 440px;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &--video {
            max-width: 640px;
            height: 440px;

            .video-js.vjs-fluid {
                padding-top: 440px;
            }
        }

        @include mq($until: $viewport--sm) {
            margin-top: 40px;
            margin-bottom: 20px;
            
            > * {
                &:nth-child(n+1) {
                    margin-top: 20px;
                }
            }

            &--topTitle {
                margin-bottom: 10px;

                h2 {
                    font-size: 44px;
                    line-height: 54px;
                }
            }

            &--titleText {
                h2 {
                    font-size: 22px;
                    line-height: 26px;
                }
            }

            &--titleBig {
                font-size: 44px;
                line-height: 54px;
            }

            &--video {
                height: max-content;

                .video-js.vjs-fluid {
                    padding-top: 350px;
                }
            }
        }
    }
}