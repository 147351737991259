.PrivacyPagesPage {
    .Privacy-subtitle {
        margin-bottom: 20px;
    }

    .Privacy-text {
        ul {
            li {
                list-style: disc;
                font-size: 20px;
                line-height: 26px;
            }
        }
    }
}