.HomePage {
    .page-header {
        .text {
            h1 {
                letter-spacing: 3px;

                @include mq($until: $viewport--md) {
                    letter-spacing: 2px;
                }

                @include mq($from: $viewport--md, $until: 1700px) {
                    font-size: 64px;
                    line-height: 66px;
                }
            }
        }
    }

    .machines {
        max-width: $container;
        margin: -160px auto 160px;
        padding: 160px 20px 0;

        &-intro {
            display: flex;

            .left {
                max-width: 460px;
                width: 100%;
                margin-right: 290px;
            }

            .right {
                max-width: 850px;
                width: 100%;

                > div {
                    max-width: 650px;
                    width: 100%;
                }

                > ul {
                    display: flex;
                    flex-wrap: wrap;
                    padding-left: 0;
                    margin-bottom: 0;

                    li {
                        list-style: none;
                        margin-right: 20px;
                        margin-bottom: 20px;
                    }
                }
            }

            @include mq($until: 1500px) {
                .left {
                    margin-right: 100px;
                }
            }

            @include mq($until: 1150px) {
                .left {
                    margin-right: 50px;
                }
            }

            @include mq($until: 960px) {
                flex-direction: column;

                .right {
                    margin-top: 40px;
                }
            }
        }

        .machine-overview {
            @include mq($until: 1500px) {
                grid-template-columns: repeat(3, 1fr);
                grid-gap: 50px;

                a {
                    width: 100%;
                    max-width: unset;
                }
            }

            @include mq($until: 960px) {
                margin-top: 40px;
                grid-template-columns: repeat(2, 1fr);
            }
            
            @include mq($until: 700px) {
                grid-template-columns: 100%;

                a {
                    figure {
                        height: 200px;
                    }
                }
            }
        }

        @include mq($until: $respNav) {
            margin: -40px auto 40px;
            padding: 40px 20px 0;
        }
    }

    @include mq($from: $respNav) {
        .Header {
            .Nav {
                &.Navigation {
                    &--main {
                        .logo {
                            svg {
                                path {
                                    fill: $cc-white;
                                }
                            }
                        }
    
                        nav {
                            ul {
                                li {
                                    > a {
                                        color: $cc-white;

                                        &:hover {
                                            color: $cc-blue;
                                        }
                                    }

                                    .sub-items {
                                        div {
                                            background-color: #444444;

                                            a {
                                                &:hover {
                                                    color: $cc-blue;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}